
require('normalize.css/normalize.css');
require('./styles/index.scss');

document.addEventListener("DOMContentLoaded", () => {
	let navOpen = false;
	let page = $('body').attr('page');
	let isMobile = false;
	
	if(window.matchMedia("(max-width: 767px)").matches){
        // The viewport is less than 768 pixels wide
        console.log("This is a mobile device.");
        isMobile = true;
    } else{
        // The viewport is at least 768 pixels wide
        console.log("This is a tablet or desktop.");
        isMobile = false;
    }

	console.log("Page", page);

	/**
	 * Setup Scrollmagic Header anims
	 */

	// init controller
	var controller = new ScrollMagic.Controller();

	let logoP = $('.logo-p');
	let logo5 = $('.logo-5');
	let logoPSVG = $('#svg-logo-p');
	let logo5SVG = $('#svg-logo-5');
	let logoMeta = $('#svg-logo-meta');

	let svgPOffset = '-=0';

	let logoStartColor = 'white';
	if(page == 'about' || page == 'featured' || page == 'terms' || page == 'privacy') {
		logoStartColor = 'black';
		logoPSVG.css('fill', 'black');
		logo5SVG.css('fill', 'black');
	} else {
		$('.hamburger-inner .hamburger-inner::before, .hamburger-inner::after').css('background-color', 'white');
	}

	if(page == 'contact') {
		console.log("Contact Page")
		svgPOffset = '-=1.5';
	}
	console.log("Logo Start Color", logoStartColor);

	var action = gsap.timeline()
	.set(logo5SVG, { fill:logoStartColor }, '-=0.2')
	.set(logoPSVG, { fill:logoStartColor }, '-=0.2')
	.set(logoP, { left: 4 })
	.set(logo5, { left: -5 })
	.to(logoP, { duration:1, left:-85, ease:'power2.out'}, '-=0')
	.to(logo5, { duration:1, left:'85%', ease:"power2.out" }, '-=1')
	.to(logo5SVG, { duration:2, y:'88vh', ease:"power2.out" }, '-=1')
	.to(logo5SVG, { duration:1, fill:'black', ease:"power2.out" }, '-=1.5')
	.to(logoMeta, { duration:0.2, autoAlpha:0 }, '-=2')
	.to(logoPSVG, { duration: 1, fill:'black' }, svgPOffset)

	new ScrollMagic.Scene({
		triggerElement: $('#main-content'),
		duration: "100%",
		offset: 5,
		triggerHook: 0.0
	})
	.setTween(action)
	// .addIndicators()
	.addTo(controller)

	/**
	 * Setup Nav Menu
	 */
	$('.hamburger').click((event) => {
		console.log("Nav clicked");

		if(navOpen) {
			$('.hamburger').removeClass('is-active');

			let action = gsap.timeline()
			.to($('.nav-links'), { duration: 0.2, autoAlpha: '0', ease:'power2.out'})
			.to($('.nav-content'), { duration: 0.5, width: '0vw', ease:'power2.out'})

			if(!isMobile) {
				gsap.to($('.logo-content-container'), { duration: 0.4, delay:0.3, autoAlpha: '1', ease:'power2.out'})
				gsap.to($('.logo-alt'), { duration: 0.4, delay:0.3, autoAlpha: '0', ease:'power2.out'})
			}

			navOpen = false;
		} else {
			$('.hamburger').addClass('is-active');
			$()
			
			let action = gsap.timeline()
			.to($('.nav-content'), { duration: 0.5, width: '100vw', ease:'power2.out'})
			.to($('.nav-links'), { duration: 0.7, autoAlpha: '1', ease:'power2.out'}, '-=0.2')

			if(!isMobile) {
				gsap.to($('.logo-content-container'), { duration: 0.4, autoAlpha: '0', ease:'power2.out'})
				gsap.to($('.logo-alt'), { duration: 0.4, autoAlpha: '1', ease:'power2.out'})
			}

			navOpen = true;
		}
	});

});
